import Cookies from 'js-cookie';
import _ from 'lodash';
import { GSERVICE_URL } from 'src/constants';
// import { WEB_NAME } from 'src/constants/layout';
import { convertObjPath, timestamp } from 'src/utils/helper';
import { fetchWithTimeout } from './fetch-with-timeout';

// for card
export const incrementCountview = async _article => {
  if (!_article) return null;
  let increase = 0;
  const _ga = Cookies.get('_ga');
  const _gid = Cookies.get('_gid');
  const params = {
    site: 'springnews',
    ga: _ga || '',
    gid: _gid || '',
    newsid: _article.id || ''
  };
  const uri = `${GSERVICE_URL}/cpvs`;
  try {
    // console.log('increment', _increment, _article.id, _article.pageviews);

    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });
    if (_.isEmpty(res)) {
      const resData = await res.json();
      // console.log('pageviews :', Number(resData), Number(_article.pageviews || 0));
      increase = Number(resData) + Number(_article.pageviews || 0);
      // console.log('increase :', increase);
      return increase;
    }
  } catch (err) {
    console.warn(`${timestamp()}==========> INCREMENT_COUNTVIEWS ERROR : ${uri}`, err);
  }
};

// for page detail
export const useCountviews = async _article => {
  if (!_article) return null;
  let increase = 0;
  const _ga = Cookies.get('_ga');
  const _gid = Cookies.get('_gid');
  const params = {
    site: 'springnews',
    ga: _ga || '',
    gid: _gid || '',
    newsid: _article.id || ''
  };
  const uri = `${GSERVICE_URL}/cpvs`;
  try {
    // console.log('increment', _article.id, _article.pageviews);
    const res = await fetchWithTimeout(`${GSERVICE_URL}/cpvs${convertObjPath(params)}`, {
      timeout: 1000
    });
    const resData = await res.json();
    // console.log('Increment pageviews : ', Number(resData || 0), '+', Number(_article.pageviews || 0), '=', Number(resData || 0) + Number(_article.pageviews || 0));
    increase = Number(resData) + Number(_article.pageviews || 0);

    // console.log('increase :', increase);
    return increase;
  } catch (err) {
    console.warn(`${timestamp()}==========> GET_COUNTVIEWS ERROR : ${uri}`, err);
  }
};
